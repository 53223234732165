




import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import SupplementForm from '@/components/forms/SupplementForm.vue';
import { ICreateSupplement } from '@/interfaces/supplements';
import { dispatchCreateSupplement } from '@/store/crud/actions';


@Component({ components: { SupplementForm } })
export default class Supplement extends AppComponent {
  @Prop({ type: String, required: false }) public supplementId: string;

  public supplementUpdated() {
    this.$router.push('/main/content/supplements');
  }

  public async supplementCreated(supplementPayload: ICreateSupplement) {
    if (supplementPayload && Object.keys(supplementPayload).length > 0) {
      const result = await dispatchCreateSupplement(this.$store, supplementPayload);
      if (result) {
          this.$router.push('/main/content/supplements');
      }
    }
  }

  public cancel() {
    this.$router.push('/main/content/supplements');
  }
}
